<template>
    <div class="layout align-center">
        <a
            href="#"
            target="_blank"
            class="mr-1"
            @click.prevent="openAdAccount(item.id)">
            {{ item.name }}
        </a>
        <styled-tooltip
            position="top"
            class="ml-auto action-icon">
            <template #content>
                View settings in Business Manager
            </template>
            <a
                href="#"
                @click.prevent="openAdAccountSettings(item.id)">
                <icon
                    name="cog"
                    size="15" />
            </a>
        </styled-tooltip>
        <styled-tooltip
            position="top"
            class="ml-2 action-icon">
            <template #content>
                View all dealer Facebook stats
            </template>
            <a
                href="#"
                @click.prevent="goToDealerStats(item.dealer.id)">
                <icon
                    name="graph"
                    size="15" />
            </a>
        </styled-tooltip>
    </div>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip';
import Icon from '@/components/globals/Icon';
import { mapState } from 'vuex';

export default {
    name: 'AdAccountLink',
    components: {
        StyledTooltip,
        Icon
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            agency: (state) => state.agency.currentAgency
        }),
    },
    methods: {
        openAdAccount(adAccount) {
            const url = `https://business.facebook.com/adsmanager/manage/campaigns?act=${adAccount}&business_id=${this.agency.facebook_business_id}`;
            window.open(url);
        },
        openAdAccountSettings(adAccount) {
            const url = `https://business.facebook.com/settings/ad-accounts/${adAccount}?business_id=${this.agency.facebook_business_id}`;
            window.open(url);
        },
        goToDealerStats(dealerId) {
            this.$router.push({
                name: 'reports-facebook-advertising',
                params: {
                    dealer_id: dealerId
                }
            });
        },
    }
};
</script>
<style scoped>
.layout{
    max-width: 280px;
}
</style>